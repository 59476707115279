import React, { useState } from 'react';
// eslint-disable-next-line
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  sendPasswordResetEmail,
  setPersistence,
  browserSessionPersistence,
} from 'firebase/auth';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Typography from '@mui/material/Typography';
import { auth } from '../../firebase.config.js';

const Authentication = () => {
  const [action, setAction] = useState('signIn');
  const [formData, setformData] = useState({
    email: '',
    password: '',
    name: '',
    confirmPassword: '',
  });
  const [viewPassword, setViewPassword] = useState(false);

  const {
    email, password, name, confirmPassword,
  } = formData;

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/;

  const onChange = (e) => {
    e.preventDefault();
    setformData((prevForm) => ({
      ...prevForm,
      [e.target.id]: e.target.value,
    }));
  };

  const toggleViewPassword = (e) => {
    e.preventDefault();
    setViewPassword(!viewPassword);
  };

  const onClickForgotPassword = async (e) => {
    e.preventDefault();
    if (!email.length) {
      toast.error('Please enter your email before password retrieval.');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      toast('Password recovery email sent');
    } catch (err) {
      toast.error('There was an error sending your password reset email. Please contact Hyphae for assistance.');
    }
  };

  const signIn = async (e) => {
    e.preventDefault();
    try {
      const isValidEmail = emailRegex.test(email);
      if (!isValidEmail || password.length < 8) {
        throw new Error('Invalid email or password!');
      }
      await setPersistence(auth, browserSessionPersistence);
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      toast.error('Invalid email or password!');
    }
  };

  const signUp = async (e) => {
    e.preventDefault();
    try {
      const isValidEmail = emailRegex.test(email);
      const isValidPassword = passwordRegex.test(password);
      if (!isValidEmail || !isValidPassword || (password !== confirmPassword) || !name.length) {
        throw new Error('Invalid email or password!');
      }

      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const { user } = userCredential;
      await updateProfile(user, {
        displayName: name,
      });
    } catch (err) {
      toast.error('Invalid email or password!');
    }
  };

  return (
    <Container
      maxWidth="sm"
      style={{
        height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center',
      }}
    >
      {action === 'signIn' && (
        <Box>
          <Typography variant="h4" gutterBottom>
            Sign In
          </Typography>
          <form onSubmit={signIn}>
            <TextField id="email" label="Email" value={email} onChange={onChange} fullWidth margin="normal" />
            <TextField
              id="password"
              label="Password"
              type={viewPassword ? 'text' : 'password'}
              value={password}
              onChange={onChange}
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={toggleViewPassword}>
                      {viewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button onClick={onClickForgotPassword} color="primary">
              Forgot password?
            </Button>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Sign In
            </Button>
          </form>
          <Button onClick={() => setAction('signUp')} color="secondary">
            Don&apos;t have an account yet? Sign up here.
          </Button>
        </Box>
      )}

      {action === 'signUp' && (
        <Box>
          <Typography variant="h4" gutterBottom>
            Sign Up
          </Typography>
          <form onSubmit={signUp}>
            <TextField
              id="name"
              label="Name"
              value={name}
              onChange={onChange}
              fullWidth
              margin="normal"
            />
            <TextField
              id="email"
              label="Email"
              value={email}
              onChange={onChange}
              fullWidth
              margin="normal"
            />
            <Typography variant="body2" color="textSecondary" gutterBottom>
              Password must be at least 8 characters long and contain at least one number
              and one special character (!@#$%^&*).
            </Typography>
            <TextField
              id="password"
              label="Password"
              type={viewPassword ? 'text' : 'password'}
              value={password}
              onChange={onChange}
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={toggleViewPassword}>
                      {viewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id="confirmPassword"
              label="Confirm Password"
              type={viewPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={onChange}
              fullWidth
              margin="normal"
            />
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Sign Up
            </Button>
          </form>
          <Button onClick={() => setAction('signIn')} color="secondary">
            Already have an account? Sign in here.
          </Button>
        </Box>
      )}
    </Container>
  );
};

export default Authentication;
