/* eslint-disable */
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAssNhbroXaWlnjZJY08hojKvDWlyvInlQ",
  authDomain: "ecosystemhealthcare.firebaseapp.com",
  databaseURL: "https://ecosystemhealthcare-default-rtdb.firebaseio.com",
  projectId: "ecosystemhealthcare",
  storageBucket: "ecosystemhealthcare.appspot.com",
  messagingSenderId: "440011123981",
  appId: "1:440011123981:web:78c99d1486c95a7387d444",
  measurementId: "G-EPNPFR9J2V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage(app);

