import React, { useContext, Suspense, lazy } from 'react';
import {
  CircularProgress,
  IconButton,
  Box,
  Typography,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import AuthenticationSection from './pages/Authentication.jsx';
import AuthenticationContext from './context/AuthenticationContext.jsx';

const Query = lazy(() => import('./pages/Query.jsx'));
const Project = lazy(() => import('./pages/Project.jsx'));
const UploadData = lazy(() => import('./pages/UploadData.jsx'));
const LookUpPlant = lazy(() => import('./pages/LookUpPlant.jsx'));
const InitializeQuery = lazy(() => import('./pages/InitializeQuery.jsx'));
const UploadMap = lazy(() => import('./pages/UploadMap.jsx'));
const ViewProjectMap = lazy(() => import('./pages/ViewProjectMap.jsx'));
const InventoryPlantList = lazy(() => import('./pages/InventoryPlantList.jsx'));
const InitializeTreePacker = lazy(() => import('./pages/InitializeTreePacker.jsx'));

const App = () => {
  const { authStatus, signOutUser } = useContext(AuthenticationContext);

  const page = document.getElementById('react').getAttribute('data-id');

  const viewDict = {
    query: <Query />,
    lookUpPlant: <LookUpPlant />,
    projects: <Project />,
    uploadData: <UploadData />,
    initializeQuery: <InitializeQuery />,
    uploadMap: <UploadMap />,
    viewProjectMap: <ViewProjectMap />,
    inventoryPlantList: <InventoryPlantList />,
    initializeTreePacker: <InitializeTreePacker />,
  };

  const renderPage = () => (
    <Suspense
      fallback={(
        <div style={{ display: 'flex', p: 2 }}>
          <CircularProgress />
        </div>
      )}
    >
      {viewDict[page]}
    </Suspense>
  );

  if (authStatus) {
    return (
      <Box>
        {renderPage()}
        <IconButton onClick={signOutUser} color="inherit" aria-label="sign out">
          <LogoutIcon />
          <Typography variant="button" style={{ marginLeft: '8px' }}>Sign Out</Typography>
        </IconButton>
      </Box>
    );
  }

  return <AuthenticationSection />;
};

export default App;
