// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: 'Exo', sans-serif;
}

body {
  margin: 0;
  background-color: #f1f1f1;
  font-family: 'Exo', sans-serif;
}

div {
  display: block;
  font-family: 'Exo', sans-serif;
}

.body-new {
  background-color: #4da350;
}

.body-grey {
  background-color: #f1f1f1;
}

button {
  cursor: pointer;
}

.list-item-limit-padding {
  padding: 4px;
}

.rotate-icon {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.box-component {
  width: 20vw;
  min-width: 250px;
  max-height: 40vh;
  text-align: right;
  overflow: auto;
}

.box-component-text-align-left {
  width: 20vw;
  min-width: 250px;
  max-height: 40vh;
  text-align: left;
  /* overflow: auto; */
}

.pointer {
  cursor: pointer;
}

.justify-content-space-btw {
  justify-content: space-between;
}

.mapboxgl-popup-content {
  max-height: 40vh;
  overflow-y: auto;
}

.slick-list {
  height: 100px;
  z-index: 1;
}

.slick-slide.slick-current {
  z-index: 10;
}`, "",{"version":3,"sources":["webpack://./src/style.css"],"names":[],"mappings":"CAAC;EACC,0BAA0B;EAC1B,8BAA8B;EAC9B,8BAA8B;AAChC;;AAEA;EACE,SAAS;EACT,yBAAyB;EACzB,8BAA8B;AAChC;;AAEA;EACE,cAAc;EACd,8BAA8B;AAChC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,+BAA+B;AACjC;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,UAAU;AACZ;;AAEA;EACE,WAAW;AACb","sourcesContent":[" html {\n  -ms-text-size-adjust: 100%;\n  -webkit-text-size-adjust: 100%;\n  font-family: 'Exo', sans-serif;\n}\n\nbody {\n  margin: 0;\n  background-color: #f1f1f1;\n  font-family: 'Exo', sans-serif;\n}\n\ndiv {\n  display: block;\n  font-family: 'Exo', sans-serif;\n}\n\n.body-new {\n  background-color: #4da350;\n}\n\n.body-grey {\n  background-color: #f1f1f1;\n}\n\nbutton {\n  cursor: pointer;\n}\n\n.list-item-limit-padding {\n  padding: 4px;\n}\n\n.rotate-icon {\n  cursor: pointer;\n  transition: transform 0.3s ease;\n}\n\n.box-component {\n  width: 20vw;\n  min-width: 250px;\n  max-height: 40vh;\n  text-align: right;\n  overflow: auto;\n}\n\n.box-component-text-align-left {\n  width: 20vw;\n  min-width: 250px;\n  max-height: 40vh;\n  text-align: left;\n  /* overflow: auto; */\n}\n\n.pointer {\n  cursor: pointer;\n}\n\n.justify-content-space-btw {\n  justify-content: space-between;\n}\n\n.mapboxgl-popup-content {\n  max-height: 40vh;\n  overflow-y: auto;\n}\n\n.slick-list {\n  height: 100px;\n  z-index: 1;\n}\n\n.slick-slide.slick-current {\n  z-index: 10;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
