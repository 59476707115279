import { createRoot } from 'react-dom/client';
import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './style.css';
import App from './App.jsx';
import AuthenticationProvider from './providers/AuthenticationProvider.jsx';

const container = document.getElementById('react');
const root = createRoot(container);

const theme = createTheme({
  palette: {
    primary: {
      main: '#8BC34A',
      contrastText: '#fff',
    },
    secondary: {
      main: '#050505', // #acd96f
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: '"Exo", sans-serif',
    body1: {
      fontSize: '0.75rem',
    },
  },
  TextareaAutosize: {
    fontFamily: '"Exo", sans-serif',
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        contained: {
          backgroundColor: '#8BC34A',
          '&:hover': {
            backgroundColor: '#7DAF3C',
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        thumb: {
          color: '#8BC34A',
        },
        track: {
          color: '#7DAF3C',
        },
        rail: {
          color: '#9CCF56',
        },
      },
    },
  },
});

root.render(
  <ThemeProvider theme={theme}>
    <AuthenticationProvider>
      <ToastContainer />
      <App />
    </AuthenticationProvider>
  </ThemeProvider>,
);
